<template>
  <Page :title="item ? 'Rechnung ' + item.number : 'Rechnung'" color="info">
    <template slot="help">
      Hier siehst du Details zu deiner Kursrechnung und kannst diese Bearbeiten,
      Löschen oder Ausdrucken.
    </template>
    <template slot="extension">
      <v-tabs background-color="info darken-1" hide-slider>
        <v-toolbar color="info darken-1" dense>
          <v-btn text exact :to="{ name: page }"
            ><v-icon left>mdi-arrow-left</v-icon>zurück</v-btn
          >
        </v-toolbar>
      </v-tabs>
    </template>

    <v-row>
      <v-col cols="12" md="6" lg="5" xl="4">
        <v-card v-if="item">
          <v-system-bar>Angaben</v-system-bar>
          <v-list>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-counter</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Rechnungsnummer</v-list-item-subtitle>
                <v-list-item-title>{{ item.number }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-school</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Anlass</v-list-item-subtitle>
                <v-list-item-title>{{ item.event }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-shape</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Bezeichnung</v-list-item-subtitle>
                <v-list-item-title>{{ item.description }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Erstellt am</v-list-item-subtitle>
                <v-list-item-title
                  ><DateValue :value="item.date" long />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="item.account.code === 'teachingMaterial'"
                  >mdi-book-open-variant</v-icon
                >
                <v-icon v-if="item.account.code === 'fieldTrip'"
                  >mdi-forest</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Konto</v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.account.description }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.account.code === 'teachingMaterial'">
              <v-list-item-icon><v-icon>mdi-layers</v-icon></v-list-item-icon>
              <GradesInput readonly v-model="item.grades" />
            </v-list-item>
            <v-list-item v-if="item.account.code === 'fieldTrip'">
              <v-list-item-icon> <v-icon></v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Schuljahr</v-list-item-subtitle>
                <v-list-item-title>
                  {{
                    item.schoolYear ? item.schoolYear.description : "–"
                  }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="item.payee.code == 'schoolFund'"
                  >mdi-printer</v-icon
                >
                <v-icon v-if="item.payee.code == 'original'"
                  >mdi-credit-card</v-icon
                >
                <v-icon v-if="item.payee.code == 'employee'"
                  >mdi-account</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Zahlung</v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.payee.description }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="item.payee.code === 'employee'">
              <v-list-item-icon
                ><v-icon>mdi-credit-card</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-subtitle>IBAN</v-list-item-subtitle>
                <v-list-item-title>{{ item.iban }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-list-status</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Status</v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.status.description }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list>

          <InvoiceAmount v-model="item" readonly />
          <v-divider />
          <v-card-actions v-if="canPrint">
            <v-spacer />
            <ReportButton
              v-if="item.status.code != 'created'"
              resource="report/invoice"
              :parameters="{ id: item.id }"
              text
              color="danger"
              >Rechnung</ReportButton
            >
            <ReportButton
              resource="report/invoicedetails"
              :parameters="{ id: item.id }"
              text
              color="danger"
              >Details</ReportButton
            >
          </v-card-actions>
          <v-card-actions v-if="canChange">
            <v-btn text color="danger" @click="trash()">Löschen</v-btn>
            <v-btn
              text
              color="info"
              :to="{ name: 'InvoiceEdit', params: { id: item.id } }"
              >Bearbeiten</v-btn
            >
            <v-spacer />
            <v-btn color="success" text @click="submit()">Einreichen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="7" xl="4">
        <v-card v-if="item">
          <v-system-bar>Personen</v-system-bar>
          <v-row no-gutters align="center" class="flex-nowrap">
            <v-col cols="6">
              <v-list-item class="font-weight-medium"
                ><v-list-item-icon>
                  <v-badge :content="item.details.length">
                    <v-icon>mdi-account-multiple</v-icon>
                  </v-badge> </v-list-item-icon
                >{{ item.details.amount }} Personen</v-list-item
              >
            </v-col>
            <v-col cols="2">Spesen</v-col>
            <v-col cols="4">Betrag</v-col>
          </v-row>
          <v-divider />
          <v-row
            no-gutters
            v-for="(item, index) in item.details"
            :key="index"
            class="flex-nowrap"
            align="center"
          >
            <v-col cols="6">
              <PersonItemBasic
                :disabled="item.amount == 0"
                :value="item.person"
              >
              </PersonItemBasic>
            </v-col>
            <v-col cols="2"
              ><v-checkbox
                v-if="item.person.type.code === 'teacher'"
                readonly
                v-model="item.expenses"
              ></v-checkbox
            ></v-col>
            <v-col cols="4" align-self="center" v-if="item.amount > 0">
              <v-list-item> <CurrencyValue :value="item.amount" /></v-list-item>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog width="500" v-model="printDialog" v-if="item">
      <v-card>
        <v-card-title>Weiteres Vorgehen</v-card-title>
        <v-card-text>
          <ul>
            <li>Rechnung herunterladen, ausdrucken und unterschreiben.</li>
            <li>
              Rechnung mit Originalbeleg (einseitig, ohne Bostitch) auf der
              Kanzlei abgeben.
            </li>
          </ul>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <ReportButton
            resource="report/invoice"
            :parameters="{ id: item.id }"
            text
            color="danger"
            >Rechnung</ReportButton
          >

          <v-spacer />
          <v-btn text @click="printDialog = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "common/components/CurrencyValue.vue";
import DateValue from "common/components/DateValue.vue";
import GradesInput from "./Components/GradesInput.vue";
import InvoiceAmount from "./Components/InvoiceAmount.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import ReportButton from "@/components/ReportButton.vue";
import { compareStudentsBySchoolClass } from "common/utils/people.js";
export default defineComponent({
  components: {
    CurrencyValue,
    DateValue,
    GradesInput,
    InvoiceAmount,
    PersonItemBasic,
    ReportButton,
  },
  props: {
    id: {},
    page: { type: String, default: "InvoicePending" },
  },
  data() {
    return {
      loading: false,
      item: null,
      search: "",
      printDialog: false,
    };
  },
  computed: {
    canChange() {
      return (
        this.item.status.code == "created" ||
        this.item.status.code == "rejected"
      );
    },
    canPrint() {
      return (
        this.item.status.code == "created" ||
        this.item.status.code == "submitted" ||
        this.item.status.code == "rejected" ||
        this.item.status.code === "submittedSap"
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.item = await this.apiGet({
        resource: "finance/invoice",
        id: this.id,
      });

      this.item.details.sort((a, b) =>
        compareStudentsBySchoolClass(a.person, b.person)
      );
      if (this.item.status.code == "submitted") {
        this.printDialog = true;
      }
      this.loading = false;
    },
    async submit() {
      if (
        await this.$root.confirm({
          message: "Soll diese Kursrechnung eingereicht werden?",
          color: "success",
          icon: "mdi-send",
        })
      ) {
        await this.apiPatch({
          resource: "finance/invoice",
          id: this.item.id,
          key: "action",
          value: "submit",
        });
        this.fetchData();
      }
    },
    async trash() {
      if (
        await this.$root.confirm({
          message: "Soll diese Kursrechnung gelöscht werden?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "finance/invoice",
          id: this.item.id,
        });
        this.$router.push({ name: this.page });
      }
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
